.box-grafico{
    display: flex;
    flex-direction: row;
}

.box-graf{
    width: 100%;
    margin: 10px;
}

@media screen and (max-width: 500px) {

    .box-grafico{
        display: flex;
        flex-direction: column;
    }
    
    .box-graf{
       
        margin: 0px;
    }

}