#containerBanner {
    
    margin-bottom: -80px;
}

.tituloPropostaVid {
    padding: 0 150px 0 50px;
    position: relative;
    z-index: 2;
    bottom: 380px;
    color: #fff;
    text-shadow: rgba(0, 0, 0, 1) 0px 0px 6px, rgba(0, 0, 0, 0.3) 0px 2px 20px;
    min-height: 150px;
}

.tituloPropostaVid h1 {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 600;
}

.tituloPropostaVid h2 {
    font-size: 1rem;
}

.tituloProposta {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 120px 0px 20px 0;
    margin: 0 250px;

}

.tituloProposta h1 {
    text-align: center;
    color: #383838;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 600;


}

.tituloProposta h2 {
    text-align: center;
    color: #656565;
    font-size: 1rem;

}

/*Mobile*/
@media screen and (max-width: 600px) {
    .tituloProposta {
        margin: 0 20px;
    }

    .tituloPropostaVid {
        padding: 0 120px 0 20px;
        position: relative;
        z-index: 2;
        bottom: 600px;
        color: #fff;
    }

}