#topo{      
    /*background-image: var(--bgLinear); */
    min-height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}

#topo img{
    margin-right: 20px;
    min-width: 100px;
    width: 150px;
    max-width: 200px;    
}

#topo a{
    margin: 0;
    padding: 0;
}



@media screen and (min-width: 600px) {
	#menu-nav img{       
        max-width: 350px;
    }
    #topo{      
       
        justify-content: flex-end;
       
    }
    
}


