#ContainerIcones{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.boxIcones svg{    
    
   font-size: 50px;
    
}

.boxIcones svg{
    cursor: pointer;
}

.boxIcones{
    position: relative;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0,0,0,.2);
}


.iconeAtivo{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: -5px;
    right: -5px;
    border-radius: 100%;
    background-color: #fff;
    
} 

.iconeAtivo svg{
    color: green;
    font-size: 1rem;
}