#NovoAnuncio{ 
    display: flex;
    flex-direction: column;
}

#NovoAnuncio .box-shadow {   
    padding: 0px;
    margin-top: 10px;
  }
  
  

