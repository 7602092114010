#content-to-pdf {
    background-color: #fff;
    border-radius: 10px;
    margin: 10px;
    width: 800px;
    margin-bottom: 100px;

}

#content-to-pdf p {
    font-weight: 600;

}

.btn-footer-pdf {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    position: fixed;
    gap: 5px;
    bottom: 0;
    left: -15px;
    width: 100vw;
    /* border-top: 2px solid #dee2e6; */
    z-index: 9;

}




.topo-pdf {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.elemento-rentagulo-pdf {
    width: 450px;
    height: 70px;
    border-radius: 0px 0px 0 80px;
}

.ml {
    margin-left: 40px;

}

.mr {
    margin-right: 40px;
}

.titulo-linha-um {
    margin-top: 20px;

}

.estatisticas-pdf {

    border-radius: 8px;
    margin-top: 10px;
}

.pdf-container {
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    gap: 10px;
    width: 100%;
    height: 120px;
}

.pdf-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.2;
}

.pdf-separator {
    background-color: #a4a4a4;
    width: 1.2px;
    height: 50%;
    align-self: center;
}

.pdf-separator-horizontal {
    background-color: #a4a4a4;
    height: 1.2px;


}

.cinza-escuro-pdf {
    color: #818181;
}

.cinza-claro-pdf {
    color: #a4a4a4;
}

.section-investimento {
    display: flex;
    flex-direction: row;
}



.pdf-titulo-box {
    font-size: 1.2rem;

}

.pdf-info-box {
    font-size: 2rem;
}


.titulo-central {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 2rem;
}
.text-contratacao{
    text-align: start
}


.section-foto-estatistic {
    display: flex;
    border-radius: 8px;

}

.foto-01 img {
    width: 250px;
    height: 100%;

}

.nome-locais {

    flex: 2 1;
    padding: 0 10px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;

}

.mtop {
    margin-top: 10px;

}

.h200px {
    height: 200px;
}

.pdf-bairro-local {
    font-size: .7rem;
    margin-top: -3px;

}

.foto-flex {
    flex: 1;
}

.pdf-img-1 {

    flex: 1;
}

.pdf-img-2 {

    flex: 1;
}

.fotos-pdf {
    margin-bottom: 20px;
}

.conteudo-fotos-pdf {
    display: flex;
    height: 200px;
    justify-content: flex-end;
}

.investimento-valor {
    display: flex;
    gap: 100px;
}

.investimento-valor,
.pdf-locais {
    font-size: 1.2rem;
    font-weight: 600;
}



.investimento-locais {
    display: flex;
    flex-direction: column;
}

.dados-locais {
    line-height: 1.4;
}

.texto-secundario {
    width: 350px;
    padding: 10px;
}

.titulo-rodape-pdf {
    font-size: 1.5rem;
    font-weight: 600;
}

.fotos-pdfs {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.pdfPersonaliadoPro {
    position: fixed;
    right: 20px;
    width: 300px;
    background: #fff;
    padding: 10px;
    height: 100%;
    overflow-y: scroll;
}

@media screen and (max-width: 600px) {
    .pdfPersonaliadoPro {  
        position: relative;     
        right: 0px;
        width: 100%;
        background: #fff
    }

    .btn-footer-pdf button{
        font-size: .8rem;
    }
}

@media screen and (min-width: 1200px) {
    .boxPropostaDoisPdf {        
       width: 32%;
       right: 0;
    }
    .btnStyle{
        display: none;
    }
}

