#containerNossosNumeros {
    display: flex;
    justify-content: space-evenly;
    margin: 0px;
    background-color: #202020;
    color: #fff;
    height: 400px;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
}

.boxNumeros {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

}

.boxPai {
    width: 100%;
}

.quantNumeros {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 120px;
    line-height: 45px;
    justify-content: flex-end;
}

.numeroQuant {
    font-size: 4rem;
    font-weight: 600;
}

.textoQuant {
    font-size: 1.2rem;
}

.tracoQuant {
    padding: 2px 30px;
    border-radius: 50px;
}


/*Mobile*/
@media screen and (max-width: 600px) {

    .boxNumeros {
        width: 400px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;
        /* Espaçamento opcional */
    }

   

    .numeroQuantFluxo{
        font-size: 3rem;
    }
}