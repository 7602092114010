#vid {
    background-color: #000;
}

#vid video{
    display: flex;
    align-items: center;
    opacity: .4;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;      
  }


  .load{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    color: #fff;
    z-index: 6;
  }