#modeloSite{ 
    
    
    background-color: #f9f9f9;
    min-height: 500px;
}

.topoPropostaModelo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 0;
    left: 0;
}

.tituloModeloUm{
   
    padding: 0 50px;
    font-size: 1rem;
    margin-top: 40px;
}

.tituloModeloDois{
    
    padding: 0 50px;
    font-size: .7rem;
    
}

.planosModelo{
    text-align: center;
    margin-top: 30px;
    font-size: 2rem; 
    font-weight: 600;
}

#modeloSite .box-proposta{
    display: inline-block;
    text-align: center;
    
}

.modeloPropostaCard{
    text-align: center;
}

.vidBgModelo{
    width: 100%;
    background-color: #000;
}

.vidBgModelo video{
    display: flex;
    width: 100%;
    height: 300px;
    opacity: .4;
    object-fit: cover;  
}

.boxModleoTopo{
    position: relative;
    
}

.modleoComVid{
    position: absolute;
    top: 90px;
    color: #fff;
    text-shadow: rgba(0, 0, 0, 1) 0px 0px 6px, rgba(0, 0, 0, 0.3) 0px 2px 20px;
}

.modleosemVid{
    margin-bottom: 150px;
    position: relative;
    top: 120px;
}

.textCenter{
    text-align: center;
}