#containerOndeEstamos {
   /* margin: 50px 0px; */
   background-color: #f9f9f9;
   /* padding-bottom: 20px; */
}

#containerOndeEstamos #TituloPlano {
   margin-bottom: 20px;
}

.BoxOndeEstamos {
   display: flex   ;
       flex-direction: row;
       justify-content: center;
       flex-wrap: wrap;
       align-content: flex-start;
       gap: 5px;
       font-size: 1.1rem;
       color: #252525;
       height: 500px;
       overflow-y: auto;
       overflow-x: hidden;
       padding-bottom: 20px;
}

.bory-onde-estamos{
   display: flex;
}

.child1 {
   flex: 1;
  
 }
 
 .child2 {
   flex: 2;
  
 }



.ondeEstamosEmpresas {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   border-radius: 5px;
   padding: 8px;
   opacity: 100%;
   /* width: 200px;
   height: 200px; */
   background-color: #e8e8e8;
   margin: 0 5px;
}

.ondeEstamosEmpresas:hover {
   opacity: 90%;
   transform: scale(1.02);
   /* padding-top: 15px; */

   box-shadow: 0px 0px 6px rgba(0, 0, 0, .2);
}

.w100{
   width: 100%;
   
}

.body-ondeEstamosEmpresas {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
   width: 100%;
   gap: 5px;
}

/* .linha {
   border: 1px solid #252525;
} */

.topo-empresas {
   display: flex;
   gap: 5px;  
   margin-bottom: 10px;
   width: 100%;
  

}

.titulo-bairro {
   font-size: .7rem;
}

.line-height-1 {
   line-height: 1;
}

.ondeestamosBairro {

   font-size: .8rem;

}

.ver-detalhes-terminal {
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   width: 100%;
   background-color: #fff;
   border-radius: 5px;
   color: #252525;
   padding: 5px 10px;
}

.modal-onde-estamos {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.horario-funcionamento {

   font-weight: 600;
   font-size: 1.2rem;
}

.modal-obs-terminal {
   font-size: .7rem;
}

.previa-vid {
   width: 100% !important;
}

.previa-vid a {
   color: #fff;
   text-decoration: none;
}

.rotulo-empresa {
   background-color: #bcbcbc;
   padding: 4px 7px;   
   cursor: pointer;  
   border-radius: 5px;
   color: #494949;
   font-size: .7rem;
}

/*

/*Mobile*/
@media screen and (max-width: 600px) {

   #containerOndeEstamos {
      margin-top: 70px;
   }

   #containerOndeEstamos #TituloPlano {
      padding: 0px 20px;
      font-size: 1.4rem;

   }

   .BoxOndeEstamos {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
   }

   .ondeEstamosEmpresas {
      /* width: 90vw; */
      /* margin: 0 20px; */
      height: 130px;
   }

   .fade-empresas {
      padding: '0 20px';
      width: '100vw';
   }

   .bory-onde-estamos{
      flex-direction: column;
      gap: 10px;
   }

   .body-ondeEstamosEmpresas {      
      gap: 0px;
   }

}






/* Deixa a barra de rolagem mais fina */
#containerOndeEstamos ::-webkit-scrollbar {
   width: 6px; /* Largura da barra vertical */
   height: 6px; /* Altura da barra horizontal */
 }
 
 /* Cor de fundo da barra de rolagem */
 #containerOndeEstamos ::-webkit-scrollbar-track {
   background: #f1f1f1;
   border-radius: 10px;
 }
 
 /* Cor da barra em si */
 #containerOndeEstamos ::-webkit-scrollbar-thumb {
   background: #888;
   border-radius: 10px;
 }
 
 /* Cor da barra ao passar o mouse */
 #containerOndeEstamos ::-webkit-scrollbar-thumb:hover {
   background: #555;
 }