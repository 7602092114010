/*STYle pagina anuncio*/

#lisr-primaria-card {
  width: 100%;
  margin: 5px;
}

#lisr-primaria-card span {
  margin: 0;
}

#list-anuncio {
  width: 100%;
}

#list-anuncio #topo-card-list {
  flex-direction: column;

}

.info-card-1 {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.status-btn {
  gap: 5px;
  display: flex;
}

.box-shadow-btn {
  display: flex;
  margin-bottom: 15px;
}

/*css fatura*/
#anunciante-faturas {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 60px;
}



.infos-anunciante {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.lista-faturas {
  padding: 10px;

}


.valor-fatura {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}


.tipo-pagamento-fatura {
  margin-top: -8px;
  margin-bottom: 15px;
  float: right;
}

#anunciante-faturas label,
#anunciante-faturas input {
  cursor: pointer;
}

.btns-fatura button {
  margin-right: 5px;
}


.status-fatura{
  top: 42px;
  position: absolute;
  right: 25px;
  font-weight: 600;


}

.fatura-bg-verde{
  background-color: #00ff3c3d ;
}
.fatura-bg-vermelho{
  background-color: #ff00003d  ;
}
#anunciante-faturas .form-check-input:checked {
  background-color: #27962b;
    border-color: #27962b
}


#verTodos{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  font-weight: 600;
  font-size: .8rem;
  cursor: pointer;
  opacity: .8;
}

#verTodos:hover{
  opacity: 100%;
}

@media screen and (max-width: 500px) {
  .valor-fatura {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .valor-fatura span{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .valor-fatura button{
    min-width: 80px;
  }

  #rodape-fatura-fixo{
   width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    
  }

  /* #rodape-fatura-fixo button{
    margin-right: 20px;
  } */
}