#imgError {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#imgError img {
    width: 100%;
    height: 100%;
}




@media screen and (max-width: 600px) {

    .btnsError {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}