#menu-nav .logoUser img {
    width: 60px;
}

#menu-nav {
    transition: .2s;
    position: relative;
    z-index: 2;
    /* background-color: #e8e8e8; */
    display: flex;
    flex-direction: column;

    box-shadow: 1px 0px 3px rgba(0, 0, 0, .2);
}

#menu-nav a {
    text-decoration: none;
    /* color: #000; */

}

#menu-nav .logoUser {
    margin-top: 20px;
    margin-bottom: 30px;
}

#menu-nav .logoUser {
    display: flex;
    justify-content: center;
}

.navegacao {
    padding: 0 10px;
    margin-bottom: 20px;
}







@media screen and (max-width: 600px) {

    #menu-nav.showMenu {
        right: 0;
        opacity: 9;
        max-height: 100vh;
    }

    #menu-nav.hideMenu {
        width: 0px;
        right: 100%;
    }

    #menu-nav {
        width: 100%;

    }

    #menu-nav li {
        font-size: .8rem;
    }

    #bg-menu-fundo {
        background-color: #000;
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 2;
        opacity: .4;
    }
    .navegacao {  
        overflow: scroll;
    }
}


#menu-nav li {
    opacity: 70%;
}


#menu-nav li:hover {
    opacity: 90%;

}

@media screen and (min-width: 500px) {}