

#container-caixa .box-caixa{
    padding: 20px;
}

.faturas-datas{
    display: flex;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--laranjaClaro);
   transition: .2s;
    font-size: .8rem;
}
.faturas-datas:hover{
    background-color: var(--laranjaEscuro);

}
.box-caixa-fatura{
    width: 100%;
}

.lista-datas{
    display: flex;
    flex-wrap: wrap;
}

.fatura-filtro{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;    
    align-items: center;
}



.fatura-filtro .box-card-fatura{
    width: 100%; 
}

@media screen and (max-width: 500px) {


}