

#alert-fetch-sucess{
    padding: 10px;
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 99999;
    left: 0;
    right: 0;   
    align-items: center;
    justify-content: center; 
    background-color: rgba(0,0,0,.4);
}

.card{
    width: 100%;
}
.card-footer {
    display: flex;
    justify-content: flex-end;
    gap: 5px
}

#modalTopo .modal-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#arquivoLogoDaEmpresa{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.modal-body .box-shadow{
    padding: 10px;
}


/*alerta de confirmação */

#comp-alert-confirm{
    display: flex;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.4);
  }

  #btn-alert-confirm{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  

  