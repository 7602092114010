#text-forma-pagamento-card{
    font-size: .7rem;
    margin-right: -2px;
    display: inline;
}

.quantiAnunciantesList{
    min-height: 170px;
}

.btnVerTerminal{
    background-color: #FE5600;
    border: none;
    opacity: 100%;

}

.btnVerTerminal .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #FE5600;
}

.btnVerTerminal:hover{   
    background-color: #FE5600;
    opacity: 80%;
    
}

/* .btn-listagem-plano {
    border: #fe6607;
    outline: #fe6607;
    background: #fe6607;
    opacity: .8;
    transition: .5s;
}

.btn-listagem-plano:hover {
    border: #fe6607;
    outline: #fe6607;
    background: #fe6607;
    opacity: 100;
} */