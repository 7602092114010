
.disabled{
    background-color: #bebebe !important;
    border-color: #bebebe !important;
}

@media screen and (max-width: 600px) {
	#tempo-de-contrato{
    display: flex;
    flex-direction: row;
    margin: 0px;
}
}


@media screen and (min-width: 600px) {
	#tempo-de-contrato{
    
    margin: 10px;
}
}



