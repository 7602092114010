#containerPropostas {
    min-height: 100vh;

}

#boxContainerProposta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;

}

.box-proposta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px 5px;
    padding: 25px;
    border-radius: 5px;
    border: 1px solid #ddd;
    min-width: 15rem;
    min-height: 22rem;
    background-color: #fff;
    transition: .5s;
    position: relative;

}

.box-proposta:hover {
    transform: scale(1.02);
    /* padding-top: 15px; */

    box-shadow: 0px 0px 6px rgba(0, 0, 0, .2);
}

.NomePlano {
    font-size: 1.2rem;
    font-weight: 600;
    color: #515151;


}

.precoPlano {
    font-size: 1.5rem;
    margin: 10px 0px;
    font-weight: 600;
}


.centerText {
    display: flex;
    justify-content: center;
}

.iconeProposta svg {
    font-size: 4rem;
    margin: 10px 0 10px 0;
    color: #515151;
}

.tipoPagamento {
    font-size: .8rem;
    margin-top: 12px;
}

.infoPlano {
    display: flex;
    justify-content: center;
    color: #656565;
    font-size: .8rem;
}

.descricaoPlanoModal {
    color: #656565;
    font-size: 1rem;
    line-height: 35px;
}

.infoPlano svg {
    margin-top: -2px;

}

.descricaoPlano {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    text-transform: uppercase;
}

.btnContratar {
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 13px 25px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.4s ease;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 30px;
    opacity: .7;
}

.btnContratar:hover {
    opacity: 100%;
}


#btnPlanos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;


}

.btnPlanoClass {
    display: flex;
    justify-content: center;
    /* background: #dcdcdc; */
    padding: 8px 40px;
    border-radius: 20px;
    gap: 20px;
}

#btnPlanos button {
    font-size: .7rem;
}

.btnPlano {
    display: flex;
    justify-content: center;
    border: none;
    opacity: .5;
    transition: .4s;
}


.btnPlano:hover {
    opacity: 100%;
}

.btnOpacity {
    opacity: 100%;
}

.saibaMAisPlano {
    cursor: pointer;
    margin-top: 15px;
}

.economia-fx{
    z-index: 999;
    /* background-color: #a74f4f; */
    color: #fff;
    border-radius: 5px;
    text-align: center;
    font-size: .7rem;
    position: absolute;
    top: -10px;
    right: -5px;
    padding: 5px;
}

/*Mobile*/

@media screen and (max-width: 600px) {

    #boxContainerProposta {
        display: inline-block;
        margin: 0px 20px -8px 20px;

    }

    .box-proposta {
        display: inline-block;
        min-width: 18rem
    }

    .containerProposta {
        overflow: auto;
        white-space: nowrap;
        text-align: center;
        margin-bottom: 20px;

    }

    /* .containerProposta::-webkit-scrollbar-thumb {
        background-color: rgb(254, 85, 0);
      } */

    .containerProposta::-webkit-scrollbar {
        width: 1px;
        height: 4px;
    }

    .containerProposta::-webkit-scrollbar-track {
        background: #E6E6E6;
        padding: 1px;
        margin: 20px;
    }

}