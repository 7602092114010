

#histotico-cliente{
    margin: 20px;
}


.lista-historico{
    padding: 10px 0;
    border-bottom: 1px solid #b8b8b8;
}

.topo-cliente-historico{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px
}