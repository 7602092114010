.padrao-topo {
    background-image: var(--bgLinear);
}

.escuro-topo {
    background-color: #191919;

}

.escuro-body {
    background: #2e2e2e;
    color: #FFFF;
}

.padrao-body {
    background-color: #fff;
    color: #2e2e2e;
}

.escuro-color {
    color: #fff;
}

.padrao-color {
    color: #333333;
}

.padrao-nav {
    background-color: #e8e8e8;
    color: #000;
}

.escuro-nav {
    background-color: #333333;
    color: #fff;
}


