#containerMarcasQueConfiam {
    min-height: 300px;
    margin: 50px 0px;
}

#boxLogoEmpresas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}




.logoEmpresaQueConfiam {
    width: 150px;
    height: 70px;
    background-color: #efefef;
    border-radius: 10px;
    padding: 10px;
}

.logoEmpresaQueConfiam img {
    border-radius: 10px;
}



.logoEmpresaQueConfiam img {
    width: 100%;
    height: 100%;
}

.imgBgLogo {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: .8s;
}

#quem-somos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 80px 0 80px;


}

#quem-somos h1 {
    font-size: 1rem;    
    margin-bottom: .8rem;
}

#quem-somos h2 {

    font-size: 1rem;

}



.texto-quem-somos {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
}

#quem-somos a {
    color: #fff;
    text-decoration: none;
}

.btn-instagram {
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    padding: 10px 40px;
    border-radius: 8px;
    width: 400px;
    opacity: 100%;
}

.btn-instagram:hover {
    opacity: .7;
}



/*Mobile*/
@media screen and (max-width: 600px) {

    #containerMarcasQueConfiam #TituloPlano {
        padding: 0px 20px;
        font-size: 1.4rem;

    }


    #quem-somos {
        margin: 10px 20px 0 20px;
    }

    .btn-instagram {
        width: 95%;
    }

   

}