.box-conf {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

}

.container-conf {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #F0F2F5;
    width: 250px;
    height: 250px;
    border-radius: 10px;
    padding: 10px;
    padding-top: 30px;
    cursor: pointer;

}

.container-conf:hover {
    background-color: #efefef;
}

.box-conf svg {
    font-size: 5rem;
}

.icone-config {
    display: flex;
    justify-content: center;
}

.texto-conf {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

.texto-conf-info {
    color: #979797;
}

#rodape-btn-salvar {
    display: flex; 
    justify-content: flex-end;
    gap: 10px;   
    width: 100%;
}

#rodape-btn-salvar .card {
    float: right;
}

.modal-footer .progress {
    margin-top: 10px;
    width: 100%;

}

.barra-carregando-modal {
    width: 70%;
}

.box-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.LinkProposta a,
.link-pdf {
    text-decoration: none;
    cursor: pointer;
    margin-top: 5px;

}

.link-pdf {
    margin-top: 15px;
}

.LinkProposta {
    display: flex;
    align-items: center;
}


#zapPorposta,
#instamProposta,
#emailPorposta,
#nomeEmpresaProposta,
#tituloPrimario,
#tituloSecundario {
    text-transform: initial;
}

.CorPrincipal {
    display: flex;
    justify-content: flex-start;
}



#formPropostaComercial .titulo-um {
    margin: 10px 0;
}

#formPropostaComercial label {
    margin: 8px 0;

}

#formPropostaComercial {
    display: flex;

}

.boxPropostaUm {
    flex: 2;
    padding: 10px 40px;
}

.boxPropostaUmPDF {
    flex: 2;
    margin-right: 5px;
}

.boxPropostaDois {
    flex: 1;
   
    
}

.boxPropostaDoisPdf {
    flex: 1;
    position: fixed;
    right: 15px;
    width: 300px;
    height: 86vh;
    transition: .5s;
}

.boxPropostaDoisHide {
    right: -350px;
}


.tituloPageProposta {
    font-weight: 600;
}

.centralizarTexto {
    text-align: center;
}

.boxPersonalizarProposta {
    display: flex;
    align-items: center;
    padding: 5px;
    height: 35px;
    border-radius: 5px;


}

.btnEditar {
    position: fixed;
    right: 15px;
}

.btnEditarModal {
    position: relative;
}

.btnStyle {
    background-color: #979797;
    border-radius: 5px;
    padding: 2px 7px;
    color: #fff;
    cursor: pointer;
    height: 25px;
    width: 80px;
    text-align: center;
    margin-bottom: 5px;
}

.btnStyle:hover {
    background-color: #707070;
}

.overFlowYscrol{
    overflow-y: scroll;
}

#labelVid label {
    margin: 0;
    margin-left: 7px;

}

#labelVid {
    margin-bottom: 10px;
}

.logoMarcas img {
    width: 100%;
}

.boxCorTitulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    margin: 10px 0;
}

.rangeIcone {
    margin: 15px 0;
}

/*css box cor*/

.grup-btn-footer {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
}

.hide-btn-desktop {
    display: none;
}

.hide-btn-mobile {
    display: block;
}

.footer-proposta-personalizada {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.modal-personalizado {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.417);
}

.body-proposta-personalizada {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.modal-proposta-personalizada .row {
    margin-top: 5px;
}

/*Mobile*/
@media screen and (max-width: 600px) {



    .hide-btn-desktop {
        display: block;
    }

    .boxPropostaUm {
        display: none;
    }

    .box-conf {

        display: flex;
        justify-content: center;
    }

    .compact-picker {
        width: 100% !important;
    }

    .container-conf {
        width: 100%;

    }

    .box-conf a {
        width: 100%;
    }

    .not-acess {
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: column;
        gap: 5px;
    }

    .alowAccess {
        justify-content: space-between;
        width: 100%;
    }

    #formPropostaComercial {

        flex-direction: column-reverse;

    }

    .boxPropostaDoisPdf{
        flex: 1;
        position: relative;
        right: 0px;
        width: 100%;
        height: 100vh;
        transition: .5s;
       
    }

    .btnEditarModal{
        display: none;
    }
}