.boxImagemMarcas{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.logoMarcas {
   
    cursor: pointer;
    margin: 5px 0;
    background-color: #efefef;
    padding: 5px;
   
}

.logoMarcas img{
    width: 90px;
    height: 50px;
    object-fit: fill;
}

.logoMarcasModal img{
    width: 100%;    
    object-fit: contain;
}

.btnUpload {
    width: 100%;
}