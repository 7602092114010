#list-users li {
    list-style: none;
    background-color: #f7d9c6;
    padding: 5px;
}



#list-users ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 20px 0px -20px;
}

.listar-usuarios{
    display: flex;
    justify-content: space-between;
}

.lista-interna{
    display: flex;
    gap: 10px;
}

.btn-user{
    color: #fff;
    padding: 2px 8px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-cadastrar-user{
    display: flex;
    justify-content: flex-end;
    margin: 0 20px 20px 0;
}

.btn-cadastrar-user a{
    width: 200px;
    padding: 5px 8px;
    background-color: #28b860;   
    border-radius: 5px; 
    color: #fff;   
    text-align: center; 
    cursor: pointer; 
    opacity: .8;
    text-decoration: none;
}

.btn-cadastrar-user a:hover{
    opacity: 100%;
}