#proposta-card {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 20px;

    gap: 5px;
}

.tituloPlanos{
    text-align: center;
}

#card {
    width: 180px;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,.2); */
    border-radius: 15px;
    background-color: #fff;    
    padding: 10px;
    border: solid 2px #eff3f8;
    line-height: 1.2;
}

#card img {
    width: 15px;

}

#card .plano-card {
    text-transform: uppercase;
    font-size: 1rem;   
    color: #333333;
    text-align: center;
}

#card .preco-card {
    background-color: #099868;
    width: 100%;
    padding: 4px 5px;
    border-radius: 15px;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 2;
}



#card .preco-total {
    margin-top: 5px;

    background-color: #333333;
}

#card .card-container {

    border-radius: 0 0px 15px 15px;

    padding: 10px;
    width: 90%;

    display: flex;
    flex-direction: column;

}

#card .card-filho {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    gap: 5px;
    padding: 4px 0px;
    font-size: .7rem;
}

#card .body-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

}




#card .preco-01 {
    font-size: 1rem;

}

#card .preco-02 {
    font-size: .8rem;
    margin-bottom: 15px;
    position: relative;
    bottom: 3px;
}

.text-contratacao {
    font-family: var(--Mograch);
    margin-top: 5px;
    color: var(--cinza);
    font-size: .6rem;
}




#vantagens img {
    width: 25px;
    height: 20px;

}

#vantagens{
    text-align: start;
}

.alinhamentoObs{
    display: flex;
    justify-content: center;
}

#vantagens span {
    color: var(--laranjaEscuro);
    font-weight: 600;
}

#propostas .stk-bg,
#propostas .stk-bg-img {
    height: 100px;
    border-radius: 0 0px 0px 50px;
}

#propostas .titulo-anucio {
    margin-top: 10px;
    margin-left: 10px;
}

#propostas .titulo-anucio h2 {
    margin-top: -8px;
    margin-left: 20px;
}




#card .preco-total-plano {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

#card .preco-total {
    display: flex;
    justify-content: center;
    line-height: .8;
    padding: 5px;

}

#card .preco-total .preco-02 {
    margin-bottom: -10px;

}

#card .preco-conf {
    font-size: .8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
    margin-left: 5px;
    margin-top: -8px;
}


#card .preco-economia {
    font-size: .7rem;
}

.horarioFun {
    font-family: 'Work Sans', sans-serif;
    font-size: .7rem;
}

.vantagens-texto{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: .7rem;
}