

.stk-home{
display: flex;
justify-content: space-between;
flex-direction: row;
margin-bottom: 5px;
}

.stk-home svg{
    opacity: .7;
    cursor: pointer;
    transition: .2s;
}

.stk-home svg:hover{
    opacity: 100;
    
}