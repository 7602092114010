#planos-body #list {
    /* margin: 5px; */
}

@media screen and (max-width: 600px) {
    #planos-body span {
       width: 100%;
        /* margin: 5px; */

    }
    

    #planos-body .card-title {
        font-size: 1rem;
        font-weight: 600;
    }

}