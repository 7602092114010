#card-home-fatura{
    margin-bottom: 10px;
}


#enviarCobranca{
    font-size: .8rem;
    color: green;
    cursor: pointer;
}

#enviarCobranca svg{
    font-size: .8rem;
    color: green;
    cursor: pointer;
}

#enviarCobranca:hover{
    color: rgb(0, 63, 0);
}