#card-clientes {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600; 
  transition: .5;
  opacity: .7;
}

#card-clientes:hover {
  opacity: 100;
}

.nomeTerminalCliente{
  padding: 5px;
  background-color:#cfe2ff;
  margin: 5px;
  border-radius: 5px;

}


