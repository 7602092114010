

.input-picker{
    display: none !important;
  
}

.react-datepicker__triangle{
    display: none !important;
}

.react-datepicker{
    margin-top: -25px;
}

.react-datepicker-popper{
    position: relative !important;
    inset: 0 !important;
    transform: none !important;
   
   
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    display: flex;
    font-size: 1.2rem;
    padding: 20px;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.react-datepicker__month-wrapper{
    display: flex;
    flex-direction: row;
   
}

.react-datepicker{
    width: 100%;
}

.react-datepicker__month-container {
     float: none !important;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    font-size: 1.3rem;
}

.react-datepicker__navigation{
    top: 12px !important;
}

/* .fatura-filtro .data-picker div{
    width: 100%; 
    display: flex;
    flex-direction: column;
} */

@media screen and (min-width: 500px) {
    
    }
    
    