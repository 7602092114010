

#cadastro-de-user{
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.msg-res{
    background-color: rgb(248, 233, 170);
    padding: 10px 5px;
    color: brown;
    height: 40px;
   
}