@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  transition: .2s;

}

/* input {
  text-transform: uppercase;
} */

input[type=password] {
  text-transform: none;
}
input[type=email] {
  text-transform: none;
}

.fw-100{
  font-weight: 100;
}
.fw-200{
  font-weight: 200;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}




:root {
  --laranjaEscuro: #FE5600;
  --laranjaClaro: #FDB82C;
  --cinzaEscuro: #333333;
  --boxShadow: 0px 0px 3px rgba(0, 0, 0, .3);
  --bgLinear: linear-gradient(150deg, var(--laranjaEscuro), var(--laranjaClaro));
  --radius: 5px;
}

.container-stk {
  width: 100vw;
  position: relative;
  z-index: 1;
  /* background: #f8f8f9; */
}

.stk-body {
  margin: 25px;
}

.box-shadow {
  box-shadow: var(--boxShadow);
  border-radius: var(--radius);
  min-height: 100px;
  padding: 20px;
  margin: 20px 0 20px 0;
  background: #fff;
}

.titulo-um {
  font-size: 1.5rem;
  font-weight: 500;
  /* color: #333333; */

}

.titulo-dois {
  font-size: 1rem;
  font-weight: 500;
  /* color: #333333; */

}


#container-stk {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
}



