/*Estilo input buscar com icone*/

#card-empresas {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #b2b2b2;
  padding: 5px;
  margin: 5px;
  border-radius: var(--radius);
  min-width: 100px;
  min-height: 80px;
  opacity: .7;
  transition: .2s;
}


#card-empresas:hover {
  opacity: 100;
}


#card-empresas img {
  width: 100px;
  height: 80px;
  border-radius: 5px;


}

.box-empresa {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

}

.box-empresa span {
  display: flex;
  gap: 5px;
}

/* .box-empresa .box-empresa-span {
  margin: 5px;
} */

/* #card-empresas .box-nome {
  margin: 5px;
} */



.box-empresa {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

}


.box-shadow {
  padding: 0px;
  margin-top: 10px;
}

.box-busca {
  padding: 20px;
}

.box-busca input {
  width: 100%;
}

.icone-input-buscar {
  background-image: url('../img/buscar.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  padding-right: 30px;
  /* espaço para o ícone */
  background-size: 20px;
}


.card-img img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

/* #list {
  margin: 5px;
  width: 300px;
  max-width: 300px;

} */

#topo-card-list {
  display: flex;
  justify-content: space-between;

}

#rodape-fatura-fixo {
  bottom: 0;
  position: fixed;
  width: 100vw;
  left: 0;
}

#card-terminal-ver {
  display: flex;
  flex-direction: column;
}

/*Abrir texto titulo mostar tudo*/
[data-tooltip] {
  position: relative;

}

[data-tooltip]:after {

  display: none;
  position: absolute;
  top: 30px;
  padding: 5px;
  border-radius: 3px;
  left: 0;
  content: attr(data-tooltip);

  color: rgb(40, 40, 40);

}

[data-tooltip]:hover:after {
  width: 200px;
  z-index: 999;
  text-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  font-size: .7rem;

}

/*DESCKTOP */
@media screen and (min-width: 600px) {

  .box-empresa {
    gap: 5px;
  }


  #list {
    min-width: 250px;
    max-width: 100%;
  }



  #topo-card-list svg {
    opacity: .7;
    transition: .2s;
    cursor: pointer;
  }

  #topo-card-list svg:hover {
    opacity: 100;
  }

}


@media screen and (max-width: 600px) {


  .box-empresa-span {
    width: 100%;
  }

  #list {
    margin: 5px 0;
    width: 100%;


  }

  .box-empresa {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }


  .outlet-hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  #planos-body span {
    width: 100%;
    margin: 5px;

  }


  #planos-body .card-title {
    font-size: 1rem;
    font-weight: 600;
  }

}