#ContainerEstatisticas {
    display: flex;
    min-height: 400px;
}

.boxEstatisticasUm {
    display: flex;
    flex: 1.5 1;
    justify-content: center;   
    flex-direction: column;
    align-items: center;
}

.textEstatisticas{
    color: #fff;
    line-height: 85px;
}

.textEstUm{
    font-size: 4rem;
    
}

.textEstQuant{
    font-size: 8rem;
    font-weight: 600;
}

.textEstDois{
    font-size: 2rem;
}



.boxEstatisticasDois {
    flex: 1;         
    opacity: 50%; 
}



/*Mobile*/

@media screen and (max-width: 600px) {

    #ContainerEstatisticas {
        display: flex;
        flex-direction: column;
        min-height: 450px;
    }

    .textEstQuant{
        font-size: 6rem;
        
    }

    .boxEstatisticasUmMobile {       
        flex: 0;       
    }

    .boxEstatisticasUmMobile2 {       
       margin-top: 50px;   
    }

    .boxEstatisticasDois {
       
        background-size: cover;
       
    }
    

}


