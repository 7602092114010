.section-btn-mp {
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
    flex-direction: row;
}

.mp-button,
.whatsapp-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: .7rem;
    font-weight: bold;
    padding: 5px 10px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s;
}

.mp-button {
    background-color: #009ee3;
    color: white;
}

.mp-button:hover {
    background-color: #007bb5;
}

.whatsapp-button {
    background-color: #25D366;
    color: white;
}

.whatsapp-button:hover {
    background-color: #1EBE5D;
}

.mp-button img,
.whatsapp-button img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

@media screen and (max-width: 500px) {
    .section-btn-mp {
        flex-direction: column;
    }

    

}