#containerProposta {
    background-color: #f9f9f9;
    min-height: 100vh;
}


.topoProposta {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;    
    height: 5rem;
    padding: 20px;
   
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, .2); */
}

.contatoPropostaTopo {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    gap: 10px;
}

.contatoProposta svg {
    cursor: pointer;
}

.contatoProposta svg {
    color: #353535;
    transition: .2s;
}


.contatoProposta svg:hover {
    color: #1A76D1;
}

.nomeEmpresaProposta {
    font-size: 1.4rem;   
    font-weight: 600;
}



.footerProposta {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    height: 200px;
    padding: 10px;
    color: #f1f1f1;

}

.contatoProposta {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;
}

.dadosEmpresa {
    display: flex;
    flex-direction: column;
    align-items: center;  

}

.docPorposta {    
    font-size: .7rem;
}

#TituloPlano {
    text-align: center;
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight: 600;
}



.tracoDetalhe {
    display: flex;
    justify-content: center;

}

.tracoDetalhe span {
    background-color: #656565;
    padding: 3px 50px;
    border-radius: 50px;
    margin-bottom: 10px;
}

