#svgMenu{
    position: relative;
    top: 2px;
    width: 30px;
    margin-left: 10px;
    margin-right: 5px;
    cursor: pointer;
}

@media screen and (min-width: 600px) { 
    #svgMenu{
       display: none;
    }   

}
