.modeloVideo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.modeloVideo video {
    max-width: 150px;
    min-width: 150px;
    width: 50%;
    object-fit: cover;


}


.videoModleoLoop {
    position: relative;
    width: 150px;
}

.iconeVid {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: -10px;
    right: -10px;
    border-radius: 100%;
    background-color: #fff;
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: .5s;
}

.iconeVid:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .7);
    transform: scale(1.05);
}

.iconeVidAtivo svg {
    color: green;
    font-size: 1.5rem;
}

.iconeVidInativo svg {
    color: red;
    font-size: 1.5rem;
}

.hideDisplay {
    display: none;
}

/*Mobile*/
@media screen and (max-width: 600px) {
    .modeloVideo video {
        max-width: 100%;
        width: 100%;
    }

    .videoModleoLoop {

        width: 100%;
    }
}