[data-tooltip] {
    position: relative;
   
  }
  
  [data-tooltip]:after {
    size: .7rem;
    display: none;
    position: absolute;
    top: -4px;
    padding: 5px;
    border-radius: 3px;
    right: calc(100% + 2px);
    content: attr(data-tooltip);
    white-space: nowrap;   
    color: #2e2e2e;
  }
  
  [data-tooltip]:hover:after {
    display: block;
  }